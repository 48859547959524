export function parseQuery(args: Object) {
    let query = '?';
    Object
        .keys(args)
        .sort((a, b) => a.localeCompare(b))
        .filter((key) => typeof args[key] !== 'undefined' && args[key] !== null)
        .forEach((key, idx, arr) => {
            query += `${key}=${encodeURIComponent(args[key])}${idx < arr.length - 1 ? '&' : ''}`
        })
    return query;
}
