import React, { useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import BuildRouter from "./build";

type Props = {};
function AppRouter(props: Props) {
    const location = useLocation();


    return (
        <>
            <Routes location={location}>
                <Route path={'/'} element={<Navigate to={'/build'} />} />

                <Route path={'/build/*'} element={<BuildRouter />} />
            </Routes>
        </>
    );
}

export type AppRouterProps = Props;
export default AppRouter;
