import React, {CSSProperties, useEffect, useState} from 'react';
import {Routes, useLocation, useNavigate} from 'react-router-dom';
import {Box, BoxProps, Button, ButtonGroup, styled, Container as MuiContainer} from "@mui/material";
import {StyledComponent} from "@emotion/styled";
import {SxProps, SystemProps, Theme} from "@mui/system";
import useQuery from "../../hooks/useQuery";
import QuestionsJService from "../QuestionsJService";
import QuestionsOpenTDB from "../QuestionsOpenTDB";
import {parseQuery} from "../../util/parseQuery";

export enum Services {
    JSERVICE = 'jservice',
    OPENTDB = 'opentdb'
}

type Query = {
    service: Services;
    category?: string;
    page?: string;
    count?: string;
}

const Container = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    border: '1px solid yellow',
    overflowY: 'auto',
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
    })
}))

type Props = {
    sx?: SxProps;
    style?: CSSProperties
    width?: number;
    // style: St
};
function Questions(props: Props) {
    const { style, sx, width } = props;
    const navigate = useNavigate();
    const { service: serviceQuery, page: pageQuery, category: categoryQuery, count: countQuery } = useQuery<Query>();
    const [service, setService] = useState<Services>(serviceQuery || Services.JSERVICE)

    useEffect(() => {
        if (serviceQuery !== service) {
            navigate(`.${parseQuery({
                // category: categoryQuery,
                // page: pageQuery,
                service,
                // count: countQuery,
            })}`, { replace: true })
        }
    }, [service, serviceQuery, categoryQuery, pageQuery, countQuery]);

    return (
        <Container
            sx={sx}
            style={{
                ...(style || {}),
                width,
            }}
        >
            <ButtonGroup
                fullWidth
                sx={{ marginBottom: '1rem' }}
            >
                <Button
                    variant={service === Services.JSERVICE ? 'contained' : 'outlined'}
                    onClick={() => setService(Services.JSERVICE)}
                >
                    Select from jService
                </Button>
                <Button
                    variant={service === Services.OPENTDB ? 'contained' : 'outlined'}
                    onClick={() => setService(Services.OPENTDB)}
                >
                    Select from OpenTriviaDB
                </Button>
            </ButtonGroup>

            <MuiContainer maxWidth={'xl'}>
                {service === Services.JSERVICE && (
                    <QuestionsJService />
                )}
                {service === Services.OPENTDB && (
                    <QuestionsOpenTDB/>
                )}
            </MuiContainer>
        </Container>
    )
}

export type QuestionsQuery = Query;
export type QuestionsProps = Props;
export default Questions;
