import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterLuxon';
import { createBrowserHistory } from 'history';
import { Router, HashRouter } from 'react-router-dom';
import { SnackbarProvider as _SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';
import { AppRouter } from './routers';
import { CustomThemeProvider } from './theme';
import Layout from './layout';

const SnackbarProvider = _SnackbarProvider as any;

const history = createBrowserHistory();

type Props = {
    preloader?: HTMLElement | null;
};

function togglePreloader(preloader: HTMLElement) {
    preloader.classList.add('done');
    setTimeout(() => {
        preloader.remove();
    }, 400);
}

function App(props: Props) {
    const { preloader } = props;

    useEffect(() => {
        if (preloader) togglePreloader(preloader);
    }, []);

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <Helmet
                title={'Trivia Generator'}
                defaultTitle={'Trivia Generator'}
                titleTemplate={'Trivia Generator | %s'}
            />
            <CustomThemeProvider>
                <CssBaseline />
                <HashRouter>
                    <React.Suspense fallback={'loading'}>
                        <Layout>
                            <SnackbarProvider maxSnack={3}>
                                <AppRouter />
                            </SnackbarProvider>
                        </Layout>
                    </React.Suspense>
                </HashRouter>
            </CustomThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
