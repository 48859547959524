import React, {useCallback, useEffect, useRef, useState} from 'react';
import {styled, Container as MuiContainer, Box, useTheme} from "@mui/material";
import Questions from "../../components/Questions";
import SelectedList from '../../components/SelectedList';
import useObserver from "../../hooks/useObserver";
import useDebounce from "../../hooks/useDebounce";
import useInteractiveState from "../../hooks/useInteractiveState";

const Container = styled(Box)(({ theme }) => ({
    // minHeight: '2000px'
    position: 'relative',
    flexGrow: 1,
    overflow: 'hidden',
}))

const Divider = styled(Box)(({ theme }) => ({
    position: 'absolute',
    height: '100%',
    width: 16,
    background: 'green',
    cursor: 'w-resize',
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
    })
}))

type Props = {}
function BuildHome(props: Props) {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [_fullWidth, setFullWidth] = useState(0);
    const fullWidth = useDebounce(_fullWidth, 100);
    const [midPoint, setMidPoint, interacted] = useInteractiveState(_fullWidth / 2);

    const onResize: ResizeObserverCallback = useCallback((a, b) => {
        setFullWidth(a[0].borderBoxSize[0].inlineSize)
    }, [])

    useObserver({
        element: containerRef,
        callback: onResize,
    })

    useEffect(() => {
        setMidPoint(fullWidth / 2, false);
        console.log({ fullWidth })
    }, [fullWidth])

    useEffect(() => {
        console.log({ midPoint })
    }, [ midPoint])

    return (
        <Container ref={containerRef}>

            <Questions
                width={midPoint - 12}
            />

            <Divider
                style={{ left: midPoint - 8 }}
            />

            <SelectedList
                sx={{
                    padding: theme.spacing(1)
                }}
                // width={midPoint - 12}
                style={{
                    width: midPoint - 12,
                }}
            />



        </Container>
    )
}

export type BuildHomeProps = Props;
export default BuildHome;
