import React from 'react';
import ReactDOM from 'react-dom';
import storejs from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import memoryStorage from 'store/storages/memoryStorage';
import sessionStorage from 'store/storages/sessionStorage';
import storeDefaults from 'store/plugins/defaults';
import storeEvents from 'store/plugins/events';
import validate from 'validate.js';
import App from './App';
// import * as serviceWorker from './serviceWorker';

function isEmpty(v) {
    return ['', null, undefined].includes(v);
}

validate.validators.optional = (value, options) => {
    return !isEmpty(value) ? validate.single(value, options) : null;
};

async function init() {

    window.store = window.store || {};
    window.store.local = storejs.createStore(
        [localStorage],
        [storeDefaults, storeEvents]
    );
    window.store.session = storejs.createStore(
        [sessionStorage],
        [storeDefaults, storeEvents]
    );
    window.store.memory = storejs.createStore(
        [memoryStorage],
        [storeDefaults, storeEvents]
    );

    ReactDOM.render(
        <App preloader={document.getElementById('preloader')} />,
        document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    // serviceWorker.unregister();
}
init();
