import {RefObject, useEffect, useRef} from 'react';

type Props = {
    element: RefObject<HTMLElement | null>;
    callback: ResizeObserverCallback;
}

function useObserver(props: Props) {
    const { element, callback } = props;

    const current = element && element.current;

    const observer = useRef<ResizeObserver | null>(null);

    useEffect(() => {
        // if we are already observing old element
        if (observer && observer.current && current) {
            observer.current.unobserve(current);
        }
        observer.current = new ResizeObserver(callback);
        observe();

        return () => {
            if (observer && observer.current && element &&
                element.current) {
                observer.current.unobserve(element.current);
            }
        };
    }, [current]);

    const observe = () => {
        if (element && element.current && observer.current) {
            observer.current.observe(element.current);
        }
    };

};

export type UseObserverProps = Props;
export default useObserver;
