import {MutableRefObject} from "react";

export type Method =
    | 'get' | 'GET'
    | 'delete' | 'DELETE'
    | 'head' | 'HEAD'
    | 'options' | 'OPTIONS'
    | 'post' | 'POST'
    | 'put' | 'PUT'
    | 'patch' | 'PATCH'
    | 'purge' | 'PURGE'
    | 'link' | 'LINK'
    | 'unlink' | 'UNLINK'

export function ApiFetcher<Expanded extends boolean = false>(
    signalRef?: null | MutableRefObject<null | AbortController>
) {
    return (endpoint: string, method?: Method, body?: any) => {
        let signal: AbortSignal | undefined;
        if (signalRef) {
            if (signalRef.current) signalRef.current.abort();
            signalRef.current = new AbortController();
            signal = signalRef.current.signal;
        }
        return fetch(endpoint, { signal, method: method || 'GET', body }).then(async (response) => {
            let data = '';
            try {
                data = await response.text();
                return JSON.parse(data);
            } catch (e) {
                return data;
            }
        });
    }
}
