import React, {SyntheticEvent, useCallback, useContext, useEffect, useMemo} from 'react'
import {Box, BoxProps, Button, IconButton, List, ListItem, Stack, Typography, useTheme} from "@mui/material";
import Question from "../../Question";
import {QuestionListContext} from "../../../context/questionList";
import useSWR from "swr";
import {JServiceApi, OpenTDBApi} from "../../../api";
import {ApiFetcher} from "../../../util/ApiFetcher";
import {QuestionsQuery} from "../../Questions";
import useQuery from "../../../hooks/useQuery";
import useSession from "../../../hooks/otdb/useSession";
import { Sync } from '@mui/icons-material'
import useKeyboard from "../../../hooks/useKeyboard";

type Props = BoxProps & {
    category: Pick<OpenTDBApi.Category, 'id'> | null | false;
}
function Questions(props: Props) {
    const { category, ...boxProps } = props;
    const { actions } = useContext(QuestionListContext);
    const { count: pageCount } = useQuery<QuestionsQuery>()
    const theme = useTheme();
    const { token, isValidating: isTokenRevalidating, resetting: resettingToken, reset: resetToken } = useSession();
    const fetchArgs = useMemo(() => [
        `https://opentdb.com/api.php?amount=${pageCount || 10}${category !== false ? `&category=${category?.id}` : ''}&token=${token}`
    ], [category, pageCount, token]);
    const { isValidating, error, mutate, data } = useSWR<OpenTDBApi.Questions>(fetchArgs, ApiFetcher(), {
        revalidateOnFocus: false,
        revalidateOnMount: false,
    })

    const onSelectQuestion = useCallback((evt: SyntheticEvent, question: OpenTDBApi.Question) => {
        actions.pushQuestion({
            question: question.question,
            answer: question.correct_answer,
            choices: [
                question.correct_answer,
                ...question.incorrect_answers,
            ],
            category: question.category
        });
    }, [actions.pushQuestion]);

    const categoryTitle = useMemo(() => {
        if (!category)
            return 'Select a Category or select Random Mode'
        if (!data)
            return 'Loading Category...'
        if (data.results.length === 0)
            return 'No Questions Found'
        return data.results[0].category
    }, [data, category]);

    const keyboard = useCallback((evt: KeyboardEvent) => {
        const key = [' ', 'ArrowRight'].indexOf(evt.key);
        if (key >= 0)
            evt.preventDefault();

        if (key === 1) {
            mutate();
        }
        if (key === 0) {
            resetToken();
        }
    }, [resetToken, mutate]);
    useKeyboard('otdb-questions', keyboard)

    useEffect(() => {
        if (!category && category !== false)
            return;
        if ((token && !resettingToken)) {
            mutate();
        }
    }, [resettingToken, token, category])

    useEffect(() => {
        if (!token) {
            resetToken();
        }
    }, [resetToken, token]);

    return (
        <Box {...boxProps}>
            <Stack
                paddingY={1}
                sx={{
                    ...theme.mixins.stickyTop(theme),
                    background: theme.palette.background.paper,
                    top: '0 !important',
                }}
                direction={'row'} alignItems={'center'}
            >
                <Typography variant={'h3'} fontSize={'1.35em'}>{categoryTitle}</Typography>
                <Button
                    sx={{ marginLeft: 'auto' }} onClick={() => mutate()} color={'secondary'}>Next Page</Button>
                <IconButton
                    onClick={() => resetToken()}
                    color={'primary'}
                >
                    <Sync />
                </IconButton>
            </Stack>

            <List>
                {data?.results.map((question, idx) => (
                    <ListItem
                        key={`${idx}`}
                        sx={{
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            display: 'block'
                        }}
                        button
                        onClick={(evt) => onSelectQuestion(evt, question)}
                    >
                        <Question
                            question={question.question}
                            answer={question.correct_answer}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export type QuestionsProps = Props;
export default Questions;
