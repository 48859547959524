import React, {useEffect, useMemo, useState} from 'react';
import Categories from "../jservice/Categories";
import {Box, Button, ButtonGroup, Divider} from "@mui/material";
import {JServiceApi} from "../../api";
import Questions from "../jservice/Questions";
import useQuery from "../../hooks/useQuery";
import {QuestionsQuery} from "../Questions";
import {useNavigate} from "react-router-dom";
import {parseQuery} from "../../util/parseQuery";

enum Mode {
    RANDOM,
    CATEGORY
}



type Props = {}
function QuestionsJService(props: Props) {
    const [mode, setMode] = useState<Mode>(Mode.CATEGORY);
    const { page: pageQuery } = useQuery<QuestionsQuery>();
    const [category, setCategory] = useState<Pick<JServiceApi.Category, 'id'> | null>(pageQuery ? { id: Number.parseInt(pageQuery) }  :null);

    const categoryMode = useMemo(() => (
        <>
            <Categories
                onSelectCategory={setCategory}
                selected={category}
                sx={{
                    marginBottom: '1rem'
                }}
            />

            <Questions
                category={category}
            />
        </>
    ), [category]);

    const randomMode = useMemo(() => (
        <>
            <Questions
                category={false}
            />
        </>
    ), [])

    return (
        <Box>

            <ButtonGroup fullWidth>
                <Button
                    onClick={() => setMode(Mode.CATEGORY)}
                    variant={mode === Mode.CATEGORY ? 'contained' : 'outlined'}
                >
                    Category Mode
                </Button>
                <Button
                    onClick={() => setMode(Mode.RANDOM)}
                    variant={mode === Mode.RANDOM ? 'contained' : 'outlined'}
                >
                    Random Mode
                </Button>
            </ButtonGroup>

            <Divider sx={{ margin: '1rem 0 1rem' }} />

            {mode === Mode.CATEGORY && categoryMode}
            {mode === Mode.RANDOM && randomMode}
        </Box>
    )
}

export type QuestionsJServiceProps = Props;
export default QuestionsJService;
