import React, {SyntheticEvent, useCallback, useContext, useEffect, useMemo} from 'react';
import {JServiceApi, Selected} from "../../../api";
import {Box, Button, List, ListItem, Stack, Typography, useTheme} from "@mui/material";
import useSWR from "swr";
import {ApiFetcher} from "../../../util/ApiFetcher";
import {stripHtml} from "../../../util/stripHtml";
import {QuestionListContext} from "../../../context/questionList";
import Question from "../../Question";
import useQuery from "../../../hooks/useQuery";
import {QuestionsQuery} from "../../Questions";
import {useNavigate} from "react-router-dom";
import {Sync} from "@mui/icons-material";
import useKeyboard from "../../../hooks/useKeyboard";

type Props = {
    category: Pick<JServiceApi.Category, 'id'> | null | false;
}
function Questions(props: Props) {
    const { category } = props;
    const { actions } = useContext(QuestionListContext);
    const { count: pageCount } = useQuery<QuestionsQuery>()
    const theme = useTheme();

    const fetchArgs = useMemo(() => [
        category !== false
            ? `https://jservice.io/api/category?id=${category?.id}`
            : `https://jservice.io/api/random?count=${pageCount || 10}`
    ], [category]);
    const { isValidating, error, mutate, data } = useSWR<JServiceApi.CategoryQuestions>(fetchArgs, ApiFetcher(), {
        revalidateOnFocus: false,
        revalidateOnMount: false,
    })

    const onSelectQuestion = useCallback((evt: SyntheticEvent, question: JServiceApi.Question) => {
        actions.pushQuestion({
            id: question.id,
            question: question.question,
            answer: question.answer,
            category: question.category_id
        });
    }, [actions.pushQuestion]);

    const questions: JServiceApi.Question[] = useMemo(() => {
        if (!data) return [];
        if (category === false)
            return data as any
        return data.clues;
    }, [data]);

    const keyboard = useCallback((evt) => {
        const key = [' ', 'ArrowRight', 'ArrowLeft'].indexOf(evt.key);
        if (key >= 0) {
            mutate();
        }
    }, [mutate]);
    useKeyboard('jservice-questions', keyboard, { active: category === false })

    useEffect(() => {
        if (!data) {
            mutate();
        }
    }, [category, data]);

    return (
        <Box>
            <Typography variant={'h3'} fontSize={'1.35em'}>{data?.title}</Typography>

            {category === false && (
                <Stack>
                    <Button onClick={() => mutate()}>
                        <Sync />
                        Load More
                    </Button>
                </Stack>
            )}

            <List>
                {questions?.filter((question) => question.answer && question.question).map((question) => (
                    <ListItem
                        key={question.id}
                        sx={{
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            display: 'block'
                        }}
                        button
                        onClick={(evt) => onSelectQuestion(evt, question)}
                    >
                        <Question
                            question={question.question}
                            answer={question.answer}
                        />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export type QuestionsProps = Props;
export default Questions;
