import React, { ForwardedRef, ReactNode, useCallback } from 'react';
import AppBar from '../components/AppBar';
import {styled, Box as MuiBox, BoxProps as MuiBoxProps} from '@mui/material';
import BuildDrawer from "../components/BuildDrawer";
import {useStateStorage} from "../hooks";
import {closedMixin, openedMixin} from "../theme/mixins/drawer";

const Content = styled(MuiBox)(({ theme }) => ({
    position: 'relative',
    marginTop: 56,
    minHeight: 'calc(100vh - 56px)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        marginTop: 64,
        minHeight: 'calc(100vh - 64px)',
    },
    ...openedMixin(theme, true),
    '&.minimized': {
        ...closedMixin(theme, true)
    }
}));

type Props = {
    children: ReactNode;
};

function Layout(props: Props, inRef: ForwardedRef<HTMLDivElement>) {
    const [open, setOpen] = useStateStorage<boolean>({
        defaultValue: true,
        type: 'session',
        key: 'drawer-open',
        shouldStore: true,
    })

    const onToggleDrawer = useCallback(() => {
        setOpen((prev) => !prev);
    }, [setOpen]);

    return (
        <>
            <AppBar drawerOpen={open} toggleDrawer={onToggleDrawer} />
            <BuildDrawer open={open} onClose={onToggleDrawer} />
            <Content className={!open ? 'minimized' : undefined} ref={inRef}>
                <React.Suspense fallback={'loading...'}>
                    {props.children}
                </React.Suspense>
            </Content>
        </>
    );
}

const forwarded = React.forwardRef(Layout);

forwarded.defaultProps = {};

export type LayoutProps = Props;
export default forwarded;
