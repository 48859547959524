import React, { useEffect } from 'react';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { buildTheme } from './index';
import { useStateStorage } from '../hooks';

type Props = {
    children: React.ReactNode;
    theme?: 'light' | 'dark';
};

type Context = {
    mode: 'light' | 'dark';
    toggle: (store?: boolean) => unknown;
};

export const ThemeSelectorContext = React.createContext<Context>(undefined as never);

function ThemeBase(props: Props) {
    const { children, theme: themeMode } = props;
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode, stored] = useStateStorage<'light' | 'dark'>({
        key: 'theme-mode',
        defaultValue: 'light',
        shouldStore: false,
        type: 'local',
    });
    // const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const contextValue = React.useMemo(
        () => ({
            mode,
            toggle: (store = false) => {
                // if (store) window.store.local.set('theme_mode', mode === 'light' ? 'dark' : 'light')
                setMode(mode === 'light' ? 'dark' : 'light', store);
            },
        }),
        [mode, setMode]
    );

    const theme = React.useMemo(() => buildTheme(contextValue.mode), [contextValue.mode]);

    useEffect(() => {
        if (themeMode) setMode(themeMode);
        else if (!stored) {
            setMode(prefersDarkMode ? 'dark' : 'light');
        }
    }, [themeMode, prefersDarkMode, stored]);

    return (
        <ThemeSelectorContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeSelectorContext.Provider>
    );
}

export type ThemeBaseProps = Props;
export default ThemeBase;
