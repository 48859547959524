import {useCallback, useState} from "react";

type SetAction<V = unknown> = ((prevState: V) => V) | V;
type Dispatcher<A> = (action: A, interacted?: boolean) => unknown;

function useInteractiveState<V = unknown>(defaultValue: V) {
    const [value, _setValue] = useState<V>(defaultValue);
    const [interacted, setInteracted] = useState(false);

    const setValue: Dispatcher<SetAction<V>> = useCallback((value, interact = true) => {
        _setValue(value)
        if (interact) setInteracted(interact)
    }, []);

    const reset = useCallback(() => {
        setInteracted(false);
    }, []);

    return [value, setValue, interacted, reset] as [
        V, Dispatcher<SetAction<V>>, boolean, () => void
    ];
}

export default useInteractiveState;
