import {createContext, ReactNode, Reducer, useEffect, useMemo, useReducer} from "react";
import {Selected} from "../api";
import {useStateStorage} from "../hooks";

type State = Pick<Selected.List, 'title'|'questions'>;

export enum Actions {
    UPDATE_TITLE = 'QuestionList/UPDATE_TITLE',
    PUSH_QUESTION = 'QuestionList/PUSH_QUESTION',
    REMOVE_QUESTION = 'QuestionList/REMOVE_QUESTION',
}

type ContextActions = {
    setTitle: (title: string) => unknown;
    pushQuestion: (question: Selected.Question) => unknown;
    removeQuestion: (question: string) => unknown;
}

type DispatchActions = {
    [S in keyof ContextActions]: ContextActions[S];
};

type ReducerAction = {
    type: Actions;
    payload?: any;
};

type ContextWrapper = {
    actions: ContextActions;
    state: State;
};

export const QuestionListContext = createContext<ContextWrapper>({} as never);
QuestionListContext.displayName = 'QuestionListContext';

const reducer: Reducer<State, ReducerAction> = (state, action) => {
    switch (action.type) {
        case Actions.UPDATE_TITLE:
            if (typeof action.payload !== 'string')
                return state;

            return {
                ...state,
                title: action.payload
            }

        case Actions.PUSH_QUESTION:
            if (typeof (action.payload as Selected.Question).question === 'undefined' || state.questions.findIndex((k) => k.question === action.payload?.question) >= 0)
                return state;

            return {
                ...state,
                questions: [
                    ...state.questions,
                    action.payload
                ]
            }

        case Actions.REMOVE_QUESTION:
            const questionsWithoutRemoved = [...state.questions];
            const removedIdx = questionsWithoutRemoved.findIndex((k) => k.question === action.payload);
            if (removedIdx >= 0) {
                questionsWithoutRemoved.splice(
                    removedIdx
                    ,1
                )
            }
            return {
                ...state,
                questions: questionsWithoutRemoved
            }

        default:
            return state
    }
}

function useQuestionListReducer(initialState: State) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const actions: DispatchActions = useMemo(() => ({
        setTitle: (nextTitle: string) => {
            dispatch({ type: Actions.UPDATE_TITLE, payload: nextTitle })
        },
        pushQuestion: (question: Selected.Question) => {
            dispatch({ type: Actions.PUSH_QUESTION, payload: question })
        },
        removeQuestion: (question: string) => {
            dispatch({ type: Actions.REMOVE_QUESTION, payload: question })
        }
    }), []);

    return { state, actions }
}

type Props = {
    children: ReactNode;
};

function QuestionListProvider(props: Props) {
    const { children } = props;
    const [storedState, setStoredState] = useStateStorage<State>({
        type: 'session',
        shouldStore: true,
        key: 'list-context',
        defaultValue: {
            title: '',
            questions: []
        }
    })
    const contextState = useQuestionListReducer(storedState);

    useEffect(() => {
        setStoredState(contextState.state);
    }, [contextState.state]);

    return (
        <QuestionListContext.Provider value={contextState}>
            {children}
        </QuestionListContext.Provider>
    )
}

export type QuestionListState = State;
export type QuestionListContextWrapper = ContextWrapper;
export type QuestionListContextActions = ContextActions;

export default QuestionListProvider;
