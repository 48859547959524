import {alpha, createTheme, darken, darkScrollbar} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { CSSProperties } from '@mui/material/styles/createMixins';
import {DRAWER_WIDTH} from '../conf';

declare module '@mui/material/styles/createMixins' {
    export interface Mixins {
        stickyTop: (theme: Theme) => CSSProperties;
        fancyScrollbar: (theme: Theme) => CSSProperties;
    }
}

export const buildTheme = (mode: 'light' | 'dark') =>
    createTheme(
        {
            palette: {
                mode,
            },
            zIndex: {
                appBar: 1200,
                drawer: 1100,
            },
            mixins: {
                stickyTop: (theme: Theme) => ({
                    position: 'sticky',
                    top: 56,
                    zIndex: 200,
                    [`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
                        top: 48,
                    },
                    [theme.breakpoints.up('sm')]: {
                        top: 64,
                    },
                }),
                fancyScrollbar: (theme: Theme) => ({
                    '&::-webkit-scrollbar': {
                        width: '.5em',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: `inset 0 0 6px ${alpha(theme.palette.divider, 0.3)}`,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: darken(theme.palette.divider, 0.2),
                        // outline: '1px solid slategrey'
                    },
                }),
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: mode === 'dark' ? darkScrollbar() : undefined
                },
                MuiAppBar: {
                    defaultProps: {
                        enableColorOnDark: true
                    }
                }
            }
        }, {
            drawerWidth: DRAWER_WIDTH
        }
    );

export { default as CustomThemeProvider, ThemeSelectorContext } from './provider';
export type { ThemeBaseProps } from './provider';
