import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useSWR from "swr";
import {ApiFetcher} from "../../util/ApiFetcher";
import {OpenTDBApi} from "../../api";

type Props = {};
function useSession() {
    const [command, setCommand] = useState<'reset'|'request'>('request')
    const [resetting, setResetting] = useState(false);
    const token = useRef<string | null>(null)
    const fetchArgs = useMemo(() => [
        `https://opentdb.com/api_token.php?command=${command}${command === 'reset' && token.current ? '&token=' + token.current : ''}`,
    ], [command, token.current]);

    const { isValidating, mutate, error, data } = useSWR<OpenTDBApi.RequestSession>(fetchArgs, ApiFetcher(), {
        revalidateOnReconnect: false, revalidateOnFocus: false, revalidateOnMount: false, revalidateIfStale: false
    });

    // const reset = useCallback(() => {
    //     mutate();
    //     if (token !== null)
    //         setToken(null)
    // }, [mutate, token])
    //
    // useEffect(() => {
    //     if (isValidating)
    //         setToken(null)
    //     else if (token === null && data) {
    //         console.log('set token');
    //         setToken(data.token)
    //     }
    // }, [isValidating, data]);

    useEffect(() => {
        if (data && command === 'request') {
            setCommand('reset');
        }
        if (data && token.current === null) {
            token.current = data.token;
        }
    }, [data, command]);

    useEffect(() => {
        if (command !== 'reset')
            return;
        if (isValidating) setResetting(true)
        else setResetting(false);
    }, [command, isValidating])

    return {
        isValidating,
        resetting,
        reset: mutate,
        token: data?.token || token.current || null,
        error,
    }
}

export type UseSessionProps = Props;
export default useSession;
