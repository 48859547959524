import { useLocation } from 'react-router-dom';

export function parseQuerystring(search: string) {
    const query: any = {};
    if (search.charAt(0) === '?') {
        search
            .substr(1)
            .split('&')
            .map((kvp) => {
                const parts = kvp.split('=') as any[];
                query[parts[0]] =
                    typeof parts[1] === 'undefined' ? true : decodeURIComponent(parts[1]);
            });
    }
    return query;
}

export default function useQuery<Query extends {}>(): Query {
    const location = useLocation();
    return parseQuerystring(location.search);
}
