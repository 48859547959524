import {Theme, CSSObject} from "@mui/material";

export const openedMixin = (theme: Theme, margin = false): CSSObject => ({
    marginLeft: margin ? theme.drawerWidth : undefined,
    width: margin ? undefined : theme.drawerWidth,
    transition: theme.transitions.create(margin ? 'margin-left' : 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

export const closedMixin = (theme: Theme, margin = false): CSSObject => ({
    transition: theme.transitions.create(margin ? 'margin-left' : 'width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: margin ? undefined : `calc(${theme.spacing(7)} + 1px)`,
    marginLeft: margin ? `calc(${theme.spacing(7)} + 1px)` : undefined,
    [theme.breakpoints.up('sm')]: {
        width: margin ? undefined : `calc(${theme.spacing(9)} + 1px)`,
        marginLeft: margin ? `calc(${theme.spacing(9)} + 1px)` : undefined,
    },
});
