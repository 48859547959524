import React, {ForwardedRef, useEffect, useMemo} from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Tooltip, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import {openedMixin, closedMixin} from "../../theme/mixins/drawer";

interface ExtendedMuiAppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBarComponent = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<ExtendedMuiAppBarProps>(({ theme, open }) => ({
    zIndex: (theme.zIndex as any).drawer + 1,
    transition: (theme.transitions as any).create(['width', 'margin'], {
        easing: (theme.transitions as any).easing.sharp,
        duration: (theme.transitions as any).duration.leavingScreen
    }),
    ...(open && {
        marginLeft: theme.drawerWidth,
        width: `calc(100% - ${theme.drawerWidth}px)`,
        transition: (theme.transitions as any).create(['width', 'margin'], {
            easing: (theme.transitions as any).easing.sharp,
            duration: (theme.transitions as any).duration.enteringScreen,
        }),
    }),
}));

const Slogan = styled('span')(({ theme }) => ({
    display: 'none',
    marginLeft: '.5rem',
    fontSize: '.5em',
    [theme.breakpoints.up('sm')]: {
        display: 'inline',
    },
}));

const Logo = styled('img')(({ theme }) => ({
    height: 56,
    [`${theme.breakpoints.only('xs')} and (orientation: landscape)`]: {
        height: 48,
    },
    [theme.breakpoints.up('sm')]: {
        height: 64,
    },
    marginRight: theme.spacing(2),
}));

type Props = {
    drawerOpen?: boolean;
    toggleDrawer?: (evt) => unknown;
};

function AppBar(props: Props, inRef: ForwardedRef<HTMLDivElement>) {
    const { toggleDrawer, drawerOpen } = props;
    const theme = useTheme();

    useEffect(() => {
        console.log(theme)
    }, [])

    return (
        <>
            <AppBarComponent
                open={drawerOpen}
                position={'fixed'}
                // position={sticky ? 'fixed' : 'absolute'}
                sx={{
                    zIndex: theme.zIndex.appBar + 1,
                }}
            >
                <Toolbar>
                    <IconButton
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{
                            marginRight: theme.spacing(2),
                            ...(drawerOpen && { display: 'none' }),
                        }}
                        color="inherit"
                        aria-label="open drawer"
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography sx={{ display: 'block' }} variant="h4" noWrap>
                        <span>Trivia Generator</span>
                        <Slogan>Make lists of questions</Slogan>
                    </Typography>
                </Toolbar>
            </AppBarComponent>
        </>
    );
}

const forwardedBar = React.forwardRef(AppBar);
forwardedBar.defaultProps = {
    drawerOpen: false
} as Props;

export type AppBarProps = Props;
export default forwardedBar;
