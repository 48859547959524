import React from 'react';
import {Box, BoxProps, Typography} from "@mui/material";
import {stripHtml} from "../../util/stripHtml";

type Props = BoxProps & {
    question: string;
    answer: string;
    choices?: string[];
}
function Question(props: Props) {
    const { answer, question, ...boxProps } = props;
    return (
        <Box {...boxProps}>
            <Typography variant={'h5'} fontSize={'1.2em'}>
                {stripHtml(question)}
            </Typography>
            <Typography>
                {stripHtml(answer)}
            </Typography>
        </Box>
    )
}

export type QuestionProps = Props;
export default Question;
