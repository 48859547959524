import React, {useMemo, useState} from 'react';
import {Box, Button, ButtonGroup, Divider} from "@mui/material";
import Categories from "../otdb/Categories";
import {JServiceApi, OpenTDBApi} from "../../api";
import useQuery from "../../hooks/useQuery";
import {QuestionsQuery} from "../Questions";
import Questions from "../otdb/Questions";
// import QuestionCategory from "../otdb/QuestionCategory";

enum Mode {
    RANDOM,
    CATEGORY
}

type Props = {}
function QuestionsOpenTDB(props: Props) {
    const [mode, setMode] = useState<Mode>(Mode.CATEGORY);
    const { category: categoryQuery } = useQuery<QuestionsQuery>();
    const [category, setCategory] = useState<Pick<OpenTDBApi.Category, 'id'> | null>(categoryQuery ? { id: Number.parseInt(categoryQuery) } : null);

    const categoryMode = useMemo(() => (
        <>
            <Categories
                onSelectCategory={setCategory}
                selected={category}
                sx={{
                    marginBottom: '1rem'
                }}
            />

            <Questions
                category={category}
            />
        </>
    ), [category]);

    const randomMode = useMemo(() => (
        <>
            <Questions
                category={false}
            />
        </>
    ), [])

    return (
        <Box>

            <ButtonGroup fullWidth>
                <Button
                    onClick={() => setMode(Mode.CATEGORY)}
                    variant={mode === Mode.CATEGORY ? 'contained' : 'outlined'}
                >
                    Category Mode
                </Button>
                <Button
                    onClick={() => setMode(Mode.RANDOM)}
                    variant={mode === Mode.RANDOM ? 'contained' : 'outlined'}
                >
                    Random Mode
                </Button>
            </ButtonGroup>

            <Divider sx={{ margin: '1rem 0 1rem' }} />

            {mode === Mode.CATEGORY && categoryMode}
            {mode === Mode.RANDOM && randomMode}
        </Box>
    )
}

export type QuestionsOpenTDBProps = Props;
export default QuestionsOpenTDB;
