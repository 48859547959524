import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useSWR from "swr";
import {ApiFetcher} from "../../../util/ApiFetcher";
import {
    Box,
    Collapse,
    IconButton,
    List,
    Stack,
    BoxProps as MuiBoxProps,
    StackProps as MuiStackProps,
    styled,
    Typography,
    ListItem, Tooltip, FormControl, TextField, Button, useTheme, Skeleton, MenuItem
} from "@mui/material";
import { ChevronRight, Search as SearchIcon } from '@mui/icons-material'
import {JServiceApi, Selected} from "../../../api";
import useKeyboard from "../../../hooks/useKeyboard";
import {useStateStorage} from "../../../hooks";
import useQuery from "../../../hooks/useQuery";
import {QuestionsQuery} from "../../Questions";
import {useNavigate} from "react-router-dom";
import {parseQuery} from "../../../util/parseQuery";

interface StackProps extends MuiStackProps {
    open?: boolean;
}

interface BoxProps extends MuiBoxProps {
    open?: boolean;
}

const Titlebar = styled(Stack)<StackProps>(({ theme, open }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: open ? 0 : theme.shape.borderRadius,
    borderBottomRightRadius: open ? 0 : theme.shape.borderRadius,
    padding: theme.spacing(1),
}))

const ContentWrapper = styled(Box)<BoxProps>(({ theme, open }) => ({
    zIndex: 100,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: open ? 0 : theme.shape.borderRadius,
    borderTopRightRadius: open ? 0 : theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    // overflow: 'hidden',
}))

const Content = styled(List)(({ theme}) => ({
    background: theme.palette.background.paper,
    // maxHeight: 200,
    overflowY: 'auto',
    ...(theme.mixins.fancyScrollbar(theme)),
}))

const Page = styled(Stack)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    padding: theme.spacing(1),
}))

type Props = BoxProps & {
    onSelectCategory?: (category: JServiceApi.Category | null) => unknown;
    selected?: Pick<JServiceApi.Category, 'id'> | null;
}

function Categories(props: Props) {
    const { onSelectCategory: _onSelectCategory, selected, ...boxProps } = props;
    const theme = useTheme();
    const navigate = useNavigate()
    const { service: serviceQuery, page: pageQuery, category: categoryQuery, count: countQuery } = useQuery<QuestionsQuery>()
    const listRef = useRef<HTMLOListElement | null>(null);
    const direction = useRef<'right'|'left'>('right');
    const [ open, setOpen ] = useState(true)
    const [page, _setPage] = useState(pageQuery ? Number.parseInt(pageQuery) : 1);
    // const [page, setPage] = useStateStorage({
    //     defaultValue: 0,
    //     shouldStore: true,
    //     type: 'session',
    //     key: 'jservice-category-page',
    // });
    const [goToPage, setGoToPage] = useState(page);
    const [pageCount, setPageCount] = useStateStorage({
        defaultValue: 10,
        shouldStore: true,
        type: 'session',
        key: 'jservice-category-pagecount',
    });
    const fetchArgs = useMemo(() => [
        `https://jservice.io/api/categories?count=${pageCount}&offset=${(page - 1) * pageCount}`
    ], [pageCount, page]);
    const { isValidating, error, mutate, data } = useSWR<JServiceApi.Categories>(fetchArgs, ApiFetcher(), {
        revalidateOnFocus: false,
        // revalidateIfStale: false,
        revalidateOnMount: false,
    })

    const setPage = useCallback((pg: number, doNavigation = true) => {
        if (doNavigation) {
            navigate(`.${parseQuery({
                page: '' + pg,
                category: categoryQuery,
                service: serviceQuery,
                count: countQuery,
            })}`, { replace: true })
        }
        _setPage(pg);
    }, [_setPage, serviceQuery, pageQuery, categoryQuery, countQuery])

    const onSelectCategory = useCallback((category: JServiceApi.Category | null, page?: number) => {
        if (_onSelectCategory) {
            navigate(`.${parseQuery({
                category: category?.id,
                page: page || pageQuery,
                service: serviceQuery,
                count: countQuery,
            })}`, { replace: true })
            _onSelectCategory(category)
        }
    }, [_onSelectCategory, serviceQuery, pageQuery, countQuery]);

    // const { data: d, error: e, mutate: m } = useSWR([
    //     'https://jservice.io/search?query=geography'
    // ], ApiFetcher(), {
    //     revalidateOnFocus: false,
    //     // revalidateIfStale: false,
    //     revalidateOnMount: false,
    // })

    // https://jservice.io/search?query=geography

    const keyboard = useCallback((evt) => {
        const key = ['ArrowLeft', 'ArrowRight', 'Enter', 'ArrowUp', 'ArrowDown'].indexOf(evt.key);
        if (key === 0) {
            if (onSelectCategory && data) {
                direction.current = 'right'
                setGoToPage(Math.max(page - 1, 0))
                setPage(Math.max(page - 1, 0), false)
                onSelectCategory(null, Math.max(page - 1, 0))
                // }
            }
        } else if (key === 1) {
            if (onSelectCategory && data) {
                direction.current = 'right'
                setGoToPage(Math.max(page + 1, 0))
                setPage(Math.max(page + 1, 0), false)
                onSelectCategory(null, Math.max(page + 1, 0))
                // }
            }
        } else if (key === 2) {
            setPage(goToPage)
        } else if (key === 3) {
            // scroll up
            if (onSelectCategory && data) {
                const currentIndex = data.findIndex((k) => k.id === selected?.id)
                if (currentIndex > 0 && data[currentIndex - 1] && !evt.shiftKey) {
                    onSelectCategory(data[currentIndex - 1])
                } else {
                    direction.current = 'left'
                    setGoToPage(Math.max(page - 1, 0))
                    setPage(Math.max(page - 1, 0), false)
                    onSelectCategory(null, Math.max(page - 1, 0))
                }
            }
        } else if (key === 4) {
            // scroll down
            if (onSelectCategory && data) {
                const currentIndex = data.findIndex((k) => k.id === selected?.id)
                if (currentIndex >= 0 && currentIndex + 1 < data.length && data[currentIndex + 1] && !evt.shiftKey) {
                    onSelectCategory(data[currentIndex + 1])
                } else {
                    direction.current = 'right'
                    setGoToPage(Math.max(page + 1, 0))
                    setPage(Math.max(page + 1, 0), false)
                    onSelectCategory(null, Math.max(page + 1, 0))
                }
            }
        }
    }, [onSelectCategory, page, data, selected, ]);
    useKeyboard('jservice-categories', keyboard);

    const skeleton = useMemo(() => {
        const items: ReactNode[] = [];
        for (let i = 0; i < pageCount; i++) {
            items.push(
                <ListItem key={i}>
                    <Skeleton width={'100%'} />
                </ListItem>
            )
        }

        return items;
    }, [pageCount]);

    useEffect(() => {
        if (!data) {
            mutate();
        }
        if (data && listRef.current) {
            listRef.current.scrollTo(0, 0)
        }
    }, [data]);

    useEffect(() => {
        if (data && onSelectCategory) {
            if (!selected || data.findIndex((k) => k.id === selected?.id) === -1) {
                if (typeof categoryQuery === 'undefined')
                    onSelectCategory(data[direction.current === 'right' ? 0 : data.length - 1])
                else {
                    const id = Number.parseInt(categoryQuery)
                    const idx = data.findIndex((k) => k.id === id);
                    if (idx >= 0) onSelectCategory(data[idx])
                }
            }
        }
    }, [data, onSelectCategory, selected]);

    return (
        <Box {...boxProps}>
            <Titlebar open={open} direction={'row'} alignItems={'center'}>
                <Typography variant={'h2'} fontSize={'1.4em'}>Categories</Typography>
                <IconButton onClick={() => setOpen((prev) => !prev)} sx={{ marginLeft: 'auto' }}>
                    <ChevronRight style={{ transform: `rotate(${open ? '-90deg' : '90deg'})` }}/>
                </IconButton>
            </Titlebar>
            <Collapse in={open}>
                <ContentWrapper open={open}>
                    <Content ref={listRef}>
                        {data ? data.map((item) => (
                            <ListItem
                                selected={item.id === selected?.id}
                                onClick={() => onSelectCategory ? onSelectCategory(item) : undefined}
                                key={item.id}
                                button
                            >
                                <Stack direction={'row'} alignItems={'center'} flexGrow={1}>
                                    <Typography>{item.title}</Typography>
                                    <Typography sx={{ marginLeft: 'auto' }}>{item.clues_count}</Typography>
                                </Stack>
                            </ListItem>
                        )) : skeleton}
                    </Content>
                    <Page direction={'row'} gap={2}>
                        <TextField
                            select
                            label={'Limit'}
                            size={'small'}
                            value={pageCount}
                            onChange={(e) => {
                                setPageCount(e.target.value as any)
                                navigate(`.${parseQuery({
                                    page: pageQuery,
                                    category: categoryQuery,
                                    count: e.target.value,
                                    service: serviceQuery,
                                })}`)
                            }}
                            sx={{
                                width: 80
                            }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </TextField>
                        <TextField
                            label={'Go To Page'}
                            sx={{ width: '8em' }}
                            value={goToPage}
                            onChange={(e) => setGoToPage((e.currentTarget as HTMLInputElement).valueAsNumber - 1)}
                            onBlur={() => setPage(goToPage)}
                            type={'number'}
                            size={'small'}
                        />
                        <Tooltip title={page <= 1 ? '' : 'Go back a page'}>
                            <IconButton
                                disabled={page <= 1}
                                sx={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    setPage(Math.max(0, page - 1))
                                    setGoToPage(Math.max(0, page - 1) )
                                }}
                            >
                                <ChevronRight style={{ transform: `rotate(180deg)` }}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Go to next page'}>
                            <IconButton
                                onClick={() => {
                                    setPage(page + 1)
                                    setGoToPage(page + 1)
                                }}
                            >
                                <ChevronRight />
                            </IconButton>
                        </Tooltip>
                    </Page>
                </ContentWrapper>
            </Collapse>
        </Box>
    )
}

export type CategoriesProps = Props;
export default Categories;
