import { useCallback, useEffect } from 'react';

type Fn = (evt: KeyboardEvent) => unknown;

type Props = {
    active?: boolean;
};

const globalCache: {
    [s: string]: Fn;
} = {};

let globalAttached = false;

const globalListener = (evt: KeyboardEvent) => {
    Object.values(globalCache).forEach((fn) => {
        if (evt.defaultPrevented) return;
        fn(evt);
    });
}

function useKeyboard(id: string, callback?: Fn | null, props?: Props) {
    const { active } = props || {};

    useEffect(() => {
        if (active || typeof active === 'undefined') {
            if (id && callback) {
                globalCache[id] = callback;
            }

            if (!globalAttached && Object.keys(globalCache).length > 0) {
                globalAttached = true;
                document.addEventListener('keydown', globalListener);
            }
        }

        return () => {
            delete globalCache[id];
            if (Object.keys(globalCache).length === 0) {
                globalAttached = false;
                document.removeEventListener('keydown', globalListener);
            }
        };
    }, [id, callback, globalListener, active]);
}

export type UseKeyboardProps = Props;
export default useKeyboard;
