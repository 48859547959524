import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
    Box,
    BoxProps as MuiBoxProps,
    StackProps as MuiStackProps,
    Collapse,
    IconButton, List,
    ListItem,
    MenuItem,
    Stack, styled,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {parseQuery} from "../../../util/parseQuery";
import {useNavigate} from "react-router-dom";
import {JServiceApi, OpenTDBApi} from "../../../api";
import useQuery from "../../../hooks/useQuery";
import {QuestionsQuery} from "../../Questions";
import useSWR from "swr";
import {ApiFetcher} from "../../../util/ApiFetcher";
import useKeyboard from "../../../hooks/useKeyboard";

interface StackProps extends MuiStackProps {
    open?: boolean;
}

interface BoxProps extends MuiBoxProps {
    open?: boolean;
}

const Titlebar = styled(Stack)<StackProps>(({ theme, open }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: open ? 0 : theme.shape.borderRadius,
    borderBottomRightRadius: open ? 0 : theme.shape.borderRadius,
    padding: theme.spacing(1),
}))

const ContentWrapper = styled(Box)<BoxProps>(({ theme, open }) => ({
    zIndex: 100,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: open ? 0 : theme.shape.borderRadius,
    borderTopRightRadius: open ? 0 : theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    // overflow: 'hidden',
}))

const Content = styled(List)(({ theme}) => ({
    background: theme.palette.background.paper,
    maxHeight: 350,
    overflowY: 'auto',
    ...(theme.mixins.fancyScrollbar(theme)),
}))

const Page = styled(Stack)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.paper,
    padding: theme.spacing(1),
}))

type Props = BoxProps & {
    onSelectCategory?: (category: OpenTDBApi.Category | null) => unknown;
    selected?: Pick<OpenTDBApi.Category, 'id'> | null;
}
function Categories(props: Props) {
    const { selected, onSelectCategory: _onSelectCategory, ...boxProps } = props;
    const { service: serviceQuery, category: categoryQuery, count: countQuery } = useQuery<QuestionsQuery>()
    const navigate = useNavigate();
    const listRef = useRef<HTMLOListElement | null>(null);
    const [ open, setOpen ] = useState(true);

    const fetchArgs = useMemo(() => [
        `https://opentdb.com/api_category.php`
    ], []);
    const { isValidating, error, mutate, data } = useSWR<OpenTDBApi.Categories>(fetchArgs, ApiFetcher(), {
        revalidateOnFocus: false,
        // revalidateIfStale: false,
        revalidateOnMount: false,
    })

    const onSelectCategory = useCallback((category: OpenTDBApi.Category | null) => {
        if (_onSelectCategory) {
            navigate(`.${parseQuery({
                category: category?.id,
                service: serviceQuery,
                count: countQuery,
            })}`, { replace: true })
            _onSelectCategory(category)
        }
    }, [_onSelectCategory, serviceQuery, countQuery]);

    const keyboard = useCallback((evt) => {
        const key = ['ArrowUp', 'ArrowDown'].indexOf(evt.key);

        if (!(_onSelectCategory && data))
            return;

        if (key === 0) {
            evt.preventDefault()
            const currentIndex = data.trivia_categories.findIndex((k) => k.id === selected?.id)
            if (currentIndex > 0 && data.trivia_categories[currentIndex - 1]) {
                onSelectCategory(data.trivia_categories[currentIndex - 1])
            } else {
                onSelectCategory(null)
            }
        }
        if (key === 1) {
            evt.preventDefault()
            const currentIndex = data.trivia_categories.findIndex((k) => k.id === selected?.id)
            if (currentIndex >= 0 && currentIndex + 1 < data.trivia_categories.length && data.trivia_categories[currentIndex + 1]) {
                onSelectCategory(data.trivia_categories[currentIndex + 1])
            } else {
                onSelectCategory(null)
            }
        }
    }, [onSelectCategory, data, selected]);
    useKeyboard('otdb-categories', keyboard)

    useEffect(() => {
        if (!data) {
            mutate();
        }
        if (data && listRef.current) {
            listRef.current.scrollTo(0, 0)
        }
    }, [data]);

    return (
        <Box {...boxProps}>
            <Titlebar open={open} direction={'row'} alignItems={'center'}>
                <Typography variant={'h2'} fontSize={'1.4em'}>Categories</Typography>
                <IconButton onClick={() => setOpen((prev) => !prev)} sx={{ marginLeft: 'auto' }}>
                    <ChevronRight style={{ transform: `rotate(${open ? '-90deg' : '90deg'})` }}/>
                </IconButton>
            </Titlebar>
            <Collapse in={open}>
                <ContentWrapper open={open}>
                    <Content ref={listRef}>
                        {data ? data.trivia_categories.map((item) => (
                            <ListItem
                                selected={item.id === selected?.id}
                                onClick={() => onSelectCategory ? onSelectCategory(item) : undefined}
                                key={item.id}
                                button
                            >
                                <Stack direction={'row'} alignItems={'center'} flexGrow={1}>
                                    <Typography>{item.name}</Typography>
                                </Stack>
                            </ListItem>
                        )) : isValidating ? (
                            <Typography>Loading Categories</Typography>
                        ) : (
                            <Typography>An Error Occurred.</Typography>
                        )}
                    </Content>
                </ContentWrapper>
            </Collapse>
        </Box>
    )
}

export type CategoriesProps = Props;
export default Categories;
