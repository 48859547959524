import React  from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import BuildPage from '../pages/build/home'
import {Box} from "@mui/material";
import QuestionListProvider from "../context/questionList";

type Props = {};
function BuildRouter(props: Props) {
    const location = useLocation();

    return (
        <QuestionListProvider>
            <Helmet title={'Builder'} />

            <Box borderBottom={1} paddingY={2} paddingX={3}>
                View Selector Menu
            </Box>

            <Routes location={location}>
                <Route path={'/'} element={<BuildPage />} />
            </Routes>
        </QuestionListProvider>
    );
}

export type BuildRouterProps = Props;
export default BuildRouter;
