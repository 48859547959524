import React, {CSSProperties, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
    Box,
    Divider, Icon,
    IconButton,
    List,
    ListItem,
    Menu,
    MenuItem, Stack,
    styled,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import { Delete as DeleteIcon, Search as SearchIcon, Edit as EditIcon } from '@mui/icons-material'
import {SxProps} from "@mui/system";
import {QuestionListContext} from "../../context/questionList";
import Question from "../Question";
import {Selected} from "../../api";

const Container = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    border: '1px solid red',
    overflowY: 'auto',
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard
    })
}))

type Props = {
    sx?: SxProps;
    style?: CSSProperties
    // style: St
};
function SelectedList(props: Props) {
    const { style, sx } = props;
    const { state, actions }= useContext(QuestionListContext);
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
        question: Selected.Question;
    } | null>(null)
    const theme = useTheme()

    const onChangeTitle = useCallback((evt: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        actions.setTitle(evt.currentTarget.value)
    }, [actions.setTitle]);

    const closeContextMenu = useCallback(() => {
        setContextMenu(null);
    }, []);

    const onRemove = useCallback((question: Selected.Question | null) => (evt: SyntheticEvent) => {
        if (question) actions.removeQuestion(question.question)
        closeContextMenu();
    }, [closeContextMenu])

    return (
        <Container
            sx={sx}
            style={style}
        >
            <Typography
                borderBottom={`1px solid ${theme.palette.divider}`}
                variant={'h2'}
                fontSize={'2em'}
                marginBottom={1}
            >
                Selected Questions
            </Typography>

            <TextField
                fullWidth
                label={'Question List Title'}
                placeholder={'Enter a name'}
                helperText={'Use this to store your question list and reuse, questions later'}
                value={state.title}
                onChange={onChangeTitle}
                sx={{
                    margin: '1rem 0 0',
                }}
            />

            <Divider sx={{ margin: '2rem 1rem' }} />

            <Menu
                open={Boolean(contextMenu)}
                onClose={closeContextMenu}
                anchorReference={'anchorPosition'}
                anchorPosition={
                    contextMenu !== null ?
                        { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <Stack direction={'row'} gap={2} paddingX={2} alignItems={'center'}>
                    <Box>
                        <IconButton
                            // onClick={onSearch(contextMenu?.question || null)}
                            color={'info'}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton
                            disabled
                            // onClick={onEdit(contextMenu?.question || null)}
                            color={'primary'}
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={onRemove(contextMenu?.question || null)}
                            color={'error'}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Stack>
            </Menu>

            <List
                sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    // padding: theme.spacing(2),
                    borderRadius: Number.parseInt(theme.shape.borderRadius + '') / 4
                }}
            >
                {state.questions.map((question, idx) => (
                    <ListItem
                        key={idx}
                        button
                        sx={{
                            borderBottom: `1px solid ${theme.palette.divider}`
                        }}
                        onClick={(evt) => {
                            if (evt.shiftKey) {
                                onRemove(question)(evt)
                                return;
                            }
                            // console.log(evt.clientX, evt.clientY);
                            setContextMenu({
                                mouseX: evt.clientX - 2,
                                mouseY: evt.clientY - 20,
                                question
                            })
                        }}
                    >
                        <Question
                            question={question.question}
                            answer={question.answer}
                        />
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

export type SelectedListProps = Props;
export default SelectedList;
